import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`San Francisco, CA, January 3, 2019, 8:00 am EST`}</strong>{`,—`}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{`, the leading conversational analytics platform, today announced it has raised an additional \\$3 million Seed Extension lead by Bessemer Venture Partners. Also participating in the round were ff Venture Capital, Samsung Next, Bertelsmann Digital Media Investments, and Runa Capital Management.`}</p>
    <p>{`Dashbot is a conversational analytics platform that enable enterprises to increase user satisfaction, engagement, and monetization through actionable insights and tools. In addition to traditional metrics like engagement and retention, Dashbot provides conversation specific analytics like Natural Language Processing (NLP) response effectiveness, sentiment analysis, and message funnels. In addition, Dashbot provides tools to take action on that data, like its Live Person Takeover of chat sessions.`}</p>
    <p>{`Dashbot is a cross platform solution that supports Alexa, Facebook Messenger, Google Assistant, Slack, web chat, SMS, and more.`}</p>
    <p>{`Having launched approximately two and half years ago, Dashbot has over 15,000 chatbots and voice skills on the platform and has processed more than 60 billion messages.`}</p>
    <p>{`The company plans to use these funds to expand the team and product offerings, including investing more into its data science tools and predictive response algorithms to optimize customers’ chatbot response effectiveness.`}</p>
    <p>{`“Conversational interfaces are the future of human computer interaction. As the use cases continue to expand, enterprises need a sophisticated set of tools to analyze and optimize their conversational interfaces to increase their ROI. We believe Dashbot is the clear leader in providing these tools,” said Alex Ferrara, Partner at Bessemer Venture Partners, who will join Dashbot’s board.`}</p>
    <p>{`Dashbot was founded by serial entrepreneurs Arte Merritt, who founded the mobile analytics platform Motally (Nokia), Jesse Hull, who co-founded Mesmo (GSN), and Dennis Yang, who co-founded Bureau of Trade (Ebay).`}</p>
    <h3>{`About Dashbot`}</h3>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enable enterprises and developers to increase user acquisition, engagement, and monetization through actionable insights and tools. In addition to traditional metrics like engagement and retention, Dashbot provides conversation specific analytics like NLP response effectiveness, sentiment analysis, and message funnels. In addition, Dashbot provides tools to take action on that data, like its Live Person Takeover of chat sessions. For more information, contact us for demo.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>
    <h3>{`About Bessemer Venture Partners`}</h3>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.bvp.com/"
        }}>{`Bessemer Venture Partners`}</a>{` is the world’s most experienced early-stage venture capital firm. With a portfolio of more than 200 companies, Bessemer helps visionary entrepreneurs lay strong foundations to create companies that matter, and supports them through every stage of their growth. The firm has backed more than 120 IPOs, including Shopify, Yelp, LinkedIn, Skype, LifeLock, Twilio, SendGrid, DocuSign, Wix, and MindBody. Bessemer’s 14 partners operate from offices in Silicon Valley, San Francisco, New York City, Boston, Israel, and India.`}</em></p>
    <h3>{`Contact`}</h3>
    <p>{`Arte Merritt`}<br parentName="p"></br>{`
`}{`Dashbot`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "mailto:press@dashbot.io"
      }}>{`press@dashbot.io`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      